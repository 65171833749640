<section class="section-head container  pb-3">
    <nav class="d-none d-md-block mb-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <button class="" (click)="goBack()" style="margin-right: 10px;  background-color: var(--Azul333);">
                <span class="cvu-icon cvu-icon-left-arrow"></span>
            </button>
            <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; last as isLast" [class.active]="isLast"
                [attr.aria-current]="isLast ? 'page' : 'false'">
                <a [routerLink]="breadcrumb.path" *ngIf="!isLast">{{ breadcrumb.label }}</a>
                <span *ngIf="isLast">{{ breadcrumb.label }}</span>
            </li>
        </ol>
    </nav>
</section>
<section class="section-body">
    <ng-content></ng-content>
</section>
