import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersPlansSummaryState } from '../reducers/users-plans-summary.reducer';

const selectUsersPlansSummaryState =
  createFeatureSelector<UsersPlansSummaryState>('usersPlansSummary');

export class UsersPlansSummarySelectors {
  static readonly selectPagination = createSelector(
    selectUsersPlansSummaryState,
    (state) => state.pagination
  );

  static readonly selectUsers = createSelector(
    selectUsersPlansSummaryState,
    (state) => state.users
  );

  static readonly selectFilters = createSelector(
    selectUsersPlansSummaryState,
    (state) => state.filters
  );

  static readonly selectLoading = createSelector(
    selectUsersPlansSummaryState,
    (state) => state.loading
  );

  static readonly selectError = createSelector(
    selectUsersPlansSummaryState,
    (state) => state.error
  );
}
