<nav class="navbar">
    <div class="w-100">
        <div class="d-flex justify-content-between flex-wrap" id="navbarSupportedContent">
            <div class="d-flex align-items-center position-relative" [ngClass]="{'align-items-end': !isBoxSearched}">
                <img src="\assets\icons\search.svg" class="position-absolute top-50 translate-middle-y ms-2"
                    style="left: 10px;" alt="Search Icon" *ngIf="isBoxSearched">
                <input *ngIf="isBoxSearched" class="form-control me-2 pl-5" type="search" placeholder="Buscar..."
                    aria-label="Search" style="padding-left: 50px;" (keyup)="onKeyPress($event)"
                    (input)="onInput($event)">
            </div>
            <ul class="navbar-nav">
                <li class="nav-item dropdown mb-0">
                    <button class="nav-link dropdown-toggle p-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="\assets\icons\profile.svg" alt="">{{username}}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end position-absolute">
                        <li class="d-flex">
                            <img src="\assets\icons\shield.svg" alt="">
                            <a class="dropdown-item" routerLink="/profile">Perfil</a>
                        </li>
                        <li>
                        </li>
                        <li class="d-flex">
                            <img src="\assets\icons\logout.svg" alt="">
                            <button class="dropdown-item" routerLink="/auth/login" (click)="logout()">Cerrar sesión</button>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
