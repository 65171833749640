import { Component, Input, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs';
import { Breadcrumb } from 'presentation/app/models/breadcrumb';

@Component({
  selector: 'app-page-container',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.css'],
})
export class PageContainerComponent {
  @Input('page-title') title: string = '';
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() breadcrumbsMobile: string = '';
  private titleService = inject(Title);

  constructor(private _location: Location, private router: Router) {
    this.setTitle();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.setTitle());
  }

  goBack() {
    this._location.back();
  }

  private setTitle(): void {
    setTimeout(() => {
      this.titleService.setTitle(`${this.title} | CVU`);
    }, 500);
  }
}
