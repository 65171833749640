import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UsersPlansSummaryActions } from '../../../../core/store/actions/users-plans-summary.actions';
import { UsersPlansSummarySelectors } from '../../../../core/store/selectors/users-plans-summary.selectors';
import { UserPlansSummaryFilters } from '@domain/models/user.model';
import { AuthService } from '@shared/services/auth/auth.service';

@Component({
  selector: 'app-float-bar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './float-bar.component.html',
  styleUrl: './float-bar.component.scss',
})
export class FloatBarComponent implements OnInit {
  username: string = 'Usuario';

  private filters?: UserPlansSummaryFilters;
  private filtersSubscription: Subscription;

  @Input() isBoxSearched: boolean = true;

  private authService = inject(AuthService);

  constructor(private store: Store) {
    const filters$ = this.store.select(
      UsersPlansSummarySelectors.selectFilters
    );

    this.filtersSubscription = filters$.subscribe((filters) => {
      this.filters = filters;
    });
  }

  ngOnInit(): void {
    const user = this.authService.getAuthData()?.user;

    if (user?.firstName && user?.lastName) {
      this.username = `${user.firstName} ${user.lastName}`;
    }
  }

  ngOnDestroy(): void {
    this.filtersSubscription.unsubscribe();
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search((event.target as HTMLInputElement)?.value ?? '');
    }
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement)?.value ?? '';

    if (value === '' && this.filters?.search && this.filters?.search !== '') {
      this.loadUsers('');
    }
  }

  logout() {
    this.authService.logout();
  }

  private search(value: string): void {
    this.loadUsers(value);
  }

  private loadUsers(value: string) {
    const filters: UserPlansSummaryFilters = {
      ...this.filters,
      pageNumber: 1,
      search: value,
    };

    this.store.dispatch(UsersPlansSummaryActions.loadUsers({ filters }));
  }
}
