import { createAction, props } from '@ngrx/store';
import {
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';
import { PaginatedData } from '@base/wrappers/paginated-data';

export class UsersPlansSummaryActions {
  static readonly loadUsers = createAction(
    '[User] Load Users Plans Summary',
    props<{ filters: UserPlansSummaryFilters }>()
  );

  static readonly loadUsersSuccess = createAction(
    '[User] Load Users Plans Summary Success',
    props<{
      pagination: PaginatedData<UserPlansSummaryModel>;
      filters: UserPlansSummaryFilters;
    }>()
  );

  static readonly loadUsersFailure = createAction(
    '[User] Load Users Plans Summary Failure',
    props<{ error: any }>()
  );

  static readonly clearUsers = createAction(
    '[User] Clear Users Plans Summary List'
  );
}
